(function () {
  'use strict';

  angular
  .module('neo.home.seasons.season')
  .config(config);

  function config($stateProvider) {
    $stateProvider
    .state('home.seasons.season', {
      url: '/season',
      templateUrl: 'home/seasons/season/season.tpl.html',
      controller: 'SeasonCtrl',
      controllerAs: 'vm'
    });
  }
}());
